import apiClient from "@/services/client";
import { groupBy } from "lodash";

export enum RatingOutcome {
  Accepted = "accepted",
  Rejected = "rejected",
  Review = "review",
  Released = "released",
}

interface APIReceivedFeedback {
  id: number;
  characteristic: string;
  question: string;
  response: string;
  sharedteammemberrating: {
    id: number;
    outcome: RatingOutcome;
    has_consent: boolean;
  };
  rating_user: {
    id: number;
    name: string;
  };
}

interface APITeamMember {
  id: number;
  name: string;
  first_name: string;
  last_name: string;
  open_feedback_received: APIReceivedFeedback[];
}

export interface APITeam {
  id: number;
  name: string;
  team_members: APITeamMember[];
}

export interface ReceivedFeedback {
  id: number;
  characteristic: string;
  question: string;
  response: string;
  sharedRating: {
    id: number;
    outcome: RatingOutcome;
    hasConsent: boolean;
  };
  ratingUser: {
    id: number;
    name: string;
  };
}

export interface TeamMember {
  id: number;
  name: string;
  firstName: string;
  lastName: string;
  openFeedbackReceived: ReceivedFeedback[];
}

export interface Team {
  id: number;
  name: string;
  teamMembers: TeamMember[];
}

const toReceivedFeedback = (
  feedback: APIReceivedFeedback
): ReceivedFeedback => ({
  ...feedback,
  ratingUser: feedback.rating_user,
  sharedRating: {
    ...feedback.sharedteammemberrating,
    hasConsent: feedback.sharedteammemberrating.has_consent,
  },
});

const toTeamMember = (member: APITeamMember): TeamMember => ({
  ...member,
  firstName: member.first_name,
  lastName: member.last_name,
  openFeedbackReceived: member.open_feedback_received.map(toReceivedFeedback),
});

const toTeam = (team: APITeam): Team => ({
  ...team,
  teamMembers: team.team_members.map(toTeamMember),
});

interface FeedbackResponse {
  from: string;
  response: string;
  sharedTeamMemberRatingId: number;
  outcome: RatingOutcome;
}

export interface FeedbackQuestion {
  question: string;
  responses: FeedbackResponse[];
}

export interface MemberFeedback {
  name: string;
  questions: FeedbackQuestion[];
}

export interface TeamFeedback {
  name: string;
  id: number;
  members: MemberFeedback[];
}

export interface PatchSharedRatingPayload {
  outcome: RatingOutcome;
}

export type FilterReceivedFeedback = (feedback: ReceivedFeedback) => boolean;

const toFeedbackResponse = (
  openFeedback: ReceivedFeedback
): FeedbackResponse => ({
  from: openFeedback.ratingUser.name,
  response: openFeedback.response,
  sharedTeamMemberRatingId: openFeedback.sharedRating.id,
  outcome: openFeedback.sharedRating.outcome,
});

const toMemberFeedback = (
  member: TeamMember,
  filterReceivedFeedback: FilterReceivedFeedback
): MemberFeedback => {
  const openFeedback = member.openFeedbackReceived.filter(
    filterReceivedFeedback
  );
  const feedbackByQuestion = groupBy(
    openFeedback,
    (feedback) => feedback.question
  );
  const questions = Object.entries(feedbackByQuestion).map(
    ([question, feedback]) => ({
      question,
      responses: feedback.map(toFeedbackResponse),
    })
  );
  return {
    name: member.name,
    questions,
  };
};

export const toTeamsWithOutcome = (
  teams: Team[],
  filterReceivedFeedback: FilterReceivedFeedback
): TeamFeedback[] => {
  const teamFeedback: TeamFeedback[] = [];
  for (const team of teams) {
    const members = team.teamMembers
      .map((member) => toMemberFeedback(member, filterReceivedFeedback))
      .filter((member) => member.questions.length);
    if (members.length) {
      teamFeedback.push({
        id: team.id,
        name: team.name,
        members: members,
      });
    }
  }
  return teamFeedback;
};

export const getTeamFeedback = async (
  cohortId: number,
  cohortSurveyId: number
): Promise<Team[]> => {
  const { data } = await apiClient.get<APITeam[]>(
    `/console/api/cohorts/${cohortId}/cohort-surveys/${cohortSurveyId}/team-feedback/`
  );
  return data.map(toTeam);
};

export const patchSharedTeamMemberRating = async (
  cohortId: number,
  cohortSurveyId: number,
  sharedRatingId: number,
  payload: PatchSharedRatingPayload
) =>
  await apiClient.patch(
    `/console/api/cohorts/${cohortId}/cohort-surveys/${cohortSurveyId}/shared-ratings/${sharedRatingId}/`,
    payload
  );

export const releaseSharedTeamMemberRatings = async (
  cohortId: number,
  cohortSurveyId: number
) =>
  await apiClient.post(
    `/console/api/cohorts/${cohortId}/cohort-surveys/${cohortSurveyId}/shared-ratings/release/`
  );
