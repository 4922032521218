import {
  UNDRAWN_GRAPH_SELECTOR,
  UNDRAWN_LEGENDS_SELECTOR,
  initGraph,
  generateLegends,
} from "@/scripts/performance-viz.js";
import { findAndInitializeSwitcherGroups } from "@/scripts/switcher-group.js";

const getUndrawnGraphs = (container) =>
  $(container)
    .find(UNDRAWN_GRAPH_SELECTOR)
    .map(function (i, el) {
      const dataUrl = $(el).data("src");
      const showSpread = $(el).data("show-spread");
      return { el, dataUrl, showSpread };
    })
    .get();

export const initializeTeamCheckViz = (container = "body") => {
  const undrawnGraphs = getUndrawnGraphs(container);
  $.each(undrawnGraphs, function (idx, { dataUrl, el, showSpread }) {
    const legendSelector = $(el).siblings(UNDRAWN_LEGENDS_SELECTOR);
    $.ajax({
      type: "GET",
      url: dataUrl,
      success: function (response) {
        initGraph(response.data, response.summary, el, showSpread);
        generateLegends(response.color_map, legendSelector);
      },
      error: function () {
        console.error("Error loading graph");
      },
    });
  });
  findAndInitializeSwitcherGroups();
};
