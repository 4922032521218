/* eslint-disable no-unused-vars */

import { tooltipHidden, tooltipVisible } from "@/scripts/tool-tip.js";
import { appendChartDescription } from "@/scripts/accessibility-helpers";

export var UNDRAWN_GRAPH_SELECTOR = ".d3-graph:not(:has(>svg))";
export var TEAM_GRAPH_SELECTOR = ".team-graph .d3-graph";
export var INDIVIDUAL_GRAPH_SELECTOR = ".individual-graph .d3-graph";
export var UNDRAWN_LEGENDS_SELECTOR = ".graph-key:empty";
export var LEGENDS_SELECTOR = ".graph-key";
export var MAX_INCREASE_SELECTOR = "#max-increase";
export var MAX_INCREASE_CONTENT_SELECTOR = "#max-increase .category";
export var MAX_DECREASE_SELECTOR = "#max-decrease";
export var MAX_DECREASE_CONTENT_SELECTOR = "#max-decrease .category";

const toMarkerPoints = (categories, getX, getY) => {
  const points = [];
  for (const category of categories) {
    for (const value of category.values) {
      const x = getX(value);
      const y = getY(value);
      if (y === null) continue;
      const label = {
        text: category.theme,
        description: category.description,
        color: category.color,
      };
      const point = points.find((p) => p.x === x && p.y === y);
      if (point) {
        point.labels.push(label);
      } else {
        points.push({
          x,
          y,
          labels: [label],
        });
      }
    }
  }
  return points;
};

const ariaStringForMarkerPoint = (markerPoint) =>
  markerPoint.labels.map((label) => label.text).join(", ");

const htmlLabelForMarkerPoint = (markerPoint) => {
  const lis = markerPoint.labels
    .map((label) => `<li>${label.text}</li>`)
    .join("");
  return `<ul>${lis}</ul>`;
};

const symbolForMarkerPoint = (markerPoint) => {
  if (markerPoint.labels.length > 1) {
    return d3.symbolSquare;
  } else {
    return d3.symbolCircle;
  }
};

const hexColorForMarkerPoint = (markerPoint) => {
  if (markerPoint.labels.length === 1) {
    return markerPoint.labels[0].color;
  } else {
    return "#5ac0e8";
  }
};

const getVizTooltip = () => {
  if ($(".visualization-tooltip").length > 0) {
    return d3.select(".visualization-tooltip").attr("aria-hidden", "true");
  } else {
    return d3
      .select("body")
      .append("div")
      .attr("class", "visualization-tooltip active-tooltip tool-tip")
      .attr("aria-hidden", "true")
      .attr("role", "tooltip")
      .style("opacity", 0);
  }
};

const renderLineChart = (
  categories,
  selector,
  yDomainDefaults,
  getY = (d) => d.score,
  getX = (d) => d.date
) => {
  const outerWidth = 550;
  const outerHeight = 225;
  const margin = {
    left: 40,
    right: 30,
    top: 10,
    bottom: 25,
  };
  const width = outerWidth - margin.left - margin.right;

  const duration = 250;
  const lineOpacity = "1";
  const lineOpacityHover = "1";
  const otherLinesOpacityHover = "0.1";
  const lineStroke = "2.5px";
  const lineStrokeHover = "3.5px";

  const circleOpacity = "1";
  const circleOpacityOnLineHover = "0.25";
  const circleRadius = 4;
  const circleRadiusHover = 5;

  const xRange = [margin.left, outerWidth - margin.right];
  const yRange = [margin.top, outerHeight - margin.bottom];
  const xValues = categories
    .flatMap((category) => category.values.map(getX))
    .sort(d3.ascending);
  const yValues = categories
    .flatMap((category) => category.values.map(getY))
    .sort(d3.ascending);
  const xDomain = d3.extent(xValues);
  const yDomain = d3
    .extent(yValues.concat(yDomainDefaults))
    .sort(d3.descending);
  const xScale = d3.scaleLinear().domain(xDomain).range(xRange);
  const yScale = d3.scaleLinear().domain(yDomain).range(yRange).nice();

  const xAxis = d3
    .axisBottom(xScale)
    .tickValues(xScale.ticks().filter(Number.isInteger));
  const yAxis = d3.axisLeft(yScale);

  const markerPoints = toMarkerPoints(categories, getX, getY);

  const vizToolTip = getVizTooltip();

  const line = d3
    .line()
    .defined((d) => getY(d) !== null)
    .x((d) => xScale(getX(d)))
    .y((d) => yScale(getY(d)));

  const svg = d3
    .select(selector)
    .append("svg")
    .attr("class", "chart-svg")
    .attr("role", "table")
    .attr("viewBox", `0 0 ${outerWidth} ${outerHeight}`)
    .attr("preserveAspectRatio", "none")
    .classed("svg-content-responsive", true);

  const yAxisWrapper = svg
    .append("g")
    .attr("class", "y axis")
    .attr("transform", `translate(${margin.left}, 0)`);
  yAxisWrapper.call(yAxis.tickSize(5));

  const yAxisGridLines = svg
    .append("g")
    .attr("class", "y axis gridlines")
    .attr("transform", `translate(${margin.left}, 0)`);
  yAxisGridLines.call(yAxis.tickSize(-width).tickFormat(""));

  const xAxisWrapper = svg
    .append("g")
    .attr("class", "x axis")
    .attr("transform", `translate(0, ${outerHeight - margin.bottom})`)
    .attr("role", "row");
  xAxisWrapper
    .append("g")
    .attr("role", "columnheader")
    .attr("scope", "col")
    .append("text")
    .text("Themes")
    .attr("class", "sr-only");
  xAxisWrapper.call(xAxis.tickFormat((x) => x + 1));

  const lines = svg.append("g").attr("class", "lines");

  lines
    .selectAll(".line-group")
    .data(categories)
    .enter()
    .append("g")
    .attr("class", "line-group")
    .append("path")
    .attr("class", "line")
    .attr("d", (d) => line(d.values))
    .style("stroke", (d, i) => d.color)
    .on("mouseover", function (d) {
      d3.selectAll(".line").style("opacity", otherLinesOpacityHover);
      d3.selectAll(".circle").style("opacity", circleOpacityOnLineHover);
      d3.select(this)
        .style("opacity", lineOpacityHover)
        .style("stroke-width", lineStrokeHover)
        .style("cursor", "pointer");
    })
    .on("mouseout", function (d) {
      d3.selectAll(".line").style("opacity", lineOpacity);
      d3.selectAll(".circle").style("opacity", circleOpacity);
      d3.select(this).style("stroke-width", lineStroke).style("cursor", "none");
    });

  const screenReaderDescriptions = lines
    .selectAll(".point-group")
    .data(categories)
    .enter()
    .append("g")
    .attr("role", "row")
    .style("fill", (d, _) => d.color);
  screenReaderDescriptions
    .append("text")
    .attr("role", "rowheader")
    .attr("scope", "row")
    .attr("class", "sr-only")
    .text((d) => d.theme);
  screenReaderDescriptions
    .selectAll(".point-group")
    .data((d) => d.values)
    .enter()
    .append("text")
    .attr(
      "transform",
      (d) => `translate(${xScale(getX(d))},${yScale(getY(d))})`
    )
    .attr("role", "cell")
    .attr("style", "font-size: 1px")
    .attr("class", "sr-only")
    .text((d) => d.description);

  lines
    .selectAll()
    .data(markerPoints)
    .enter()
    .append("path")
    .attr("d", d3.symbol().type(symbolForMarkerPoint))
    .attr("fill", hexColorForMarkerPoint)
    .on("mouseover", function (d) {
      d3.select(this).style("cursor", "pointer");
    })
    .attr("transform", (d) => `translate(${xScale(d.x)},${yScale(d.y)})`)
    .style("opacity", circleOpacity)
    .on("mouseover", function (d) {
      tooltipVisible(d3.event);
      d3.select(this)
        .transition()
        .duration(duration)
        .attr("r", circleRadiusHover);
      const htmlLabel = htmlLabelForMarkerPoint(d);
      const ariaString = ariaStringForMarkerPoint(d);
      vizToolTip.style("opacity", 0.9).style("display", "block");
      vizToolTip
        .html(htmlLabel)
        .style("display", "block")
        .attr("aria-label", `${ariaString}`)
        .attr("aria-hidden", "false");
    })
    .on("mouseout", function (d) {
      d3.select(this).transition().duration(duration).attr("r", circleRadius);
      vizToolTip.attr("aria-hidden", "true");
      tooltipHidden();
    });
};

export function initGraph(data, summary, selector, displaySpread = false) {
  if (!data || !data.length) return;
  const getY = displaySpread ? (d) => d.spread : (d) => d.score;
  const yDomainDefaults = displaySpread ? [0, 8] : [1, 9];
  renderLineChart(data, selector, yDomainDefaults, getY);
  appendChartDescription(selector, summary);
}

export function generateLegends(data, selector) {
  const selectorNotEmpty = !$(selector).is(":empty");
  if (!data || selectorNotEmpty) {
    return;
  }

  data.forEach(function (theme, idx) {
    if (idx == data.length - 1) {
      let temp_div = jQuery("<div/>", {
        class: "key-item key-overlap",
      });
      temp_div.appendTo(selector);
      jQuery("<div/>", {
        class: "key-color " + "overlap",
        css: {
          "background-color": theme.color,
        },
      }).appendTo(temp_div);
      let key_label = jQuery("<div/>", {
        class: "key-label",
        text: theme.theme.split(",")[0],
      }).appendTo(temp_div);
      jQuery("<span/>", {
        text: theme.theme.split(",")[1],
      }).appendTo(key_label);
    } else {
      let temp_div = jQuery("<div/>", {
        class: "key-item",
      });
      temp_div.appendTo(selector);
      jQuery("<div/>", {
        class: "key-color " + theme.theme.replace(" ", "-").toLowerCase(),
        css: {
          "background-color": theme.color,
        },
      }).appendTo(temp_div);
      jQuery("<div/>", {
        class: "key-label",
        text: theme.theme,
      }).appendTo(temp_div);
    }
  });
}

function displayDelta(theme, container, content) {
  if (theme) {
    let deltaMainContainer = container.parent(".category-changes");

    deltaMainContainer.removeClass("hidden");
    container.removeClass("hidden");
    content.text(theme);
  }
}

$(document).ready(function () {
  var dataUrl = $(UNDRAWN_GRAPH_SELECTOR).attr("data-src");
  $.ajax({
    type: "GET",
    url: dataUrl,
    success: function (response) {
      if (response.entity == "COHORT") {
        initGraph(
          response.data,
          response.summary,
          $(UNDRAWN_GRAPH_SELECTOR)[0]
        );
        generateLegends(response.color_map, $(UNDRAWN_LEGENDS_SELECTOR));
        displayDelta(
          response.max_increase_theme,
          $(MAX_INCREASE_SELECTOR),
          $(MAX_INCREASE_CONTENT_SELECTOR)
        );
        displayDelta(
          response.max_decrease_theme,
          $(MAX_DECREASE_SELECTOR),
          $(MAX_DECREASE_CONTENT_SELECTOR)
        );
      }
    },
    error: function (result) {
      console.error("Error loading graph");
    },
  });
});
