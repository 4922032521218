const activate = (container, target) => {
  $(container).find(`[data-target="${target}"]`).addClass("active");
  $(container)
    .find(`[data-target]:not([data-target="${target}"])`)
    .removeClass("active");
  $(container).find(`[data-content]:not([data-content="${target}"])`).hide();
  $(container).find(`[data-content="${target}"]`).show();
};

const activateFirstTab = (container) => {
  const target = $(container).find("[data-target]").first().data("target");
  activate(container, target);
};

const initializeSwitcherGroup = (el) => {
  activateFirstTab(el);
  $(el)
    .find("[data-target]")
    .on("click", function () {
      const value = $(this).data("target");
      activate(el, value);
    });
};

export const findAndInitializeSwitcherGroups = () => {
  $(".content-switcher").each((_, el) => initializeSwitcherGroup(el));
};
