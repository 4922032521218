import { createApp } from "vue";
import { createPinia } from "pinia";
import FloatingVue from "floating-vue";
import { vfmPlugin } from "vue-final-modal";

import AssignmentCalendar from "@/pages/CohortCalendarWrapper.vue";
import AssignmentDashboard from "@/components/AssignmentDashboard.vue";
import FaqView from "@/components/FaqView.vue";
import TaskInputWrapper from "@/components/TaskInputWrapper.vue";
import TermPlanner from "@/pages/TermPlannerWrapper.vue";
import TermTweaker from "@/pages/TermTweakerWrapper.vue";
import SessionTimeoutWarning from "@/components/SessionTimeoutWarning.vue";
import SnapshotBuilder from "@/pages/SnapshotBuilder.vue";
import StudentAudit from "@/pages/StudentAuditLogWrapper.vue";
import TeamEvaluationResults from "@/pages/TeamEvaluationResults.vue";
import CustomQuestions from "@/pages/CustomQuestions.vue";
import CustomTasks from "@/pages/CustomTasks.vue";
import FeedbackReviewer from "@/pages/FeedbackReviewer.vue";

import "floating-vue/dist/style.css";

const pinia = createPinia();

export const initializeVueInstances = () => {
  const wrappers = document.querySelectorAll(".vue-wrapper");
  wrappers.forEach((el) =>
    createApp({
      components: {
        AssignmentCalendar,
        FaqView,
        TermPlanner,
        TermTweaker,
        AssignmentDashboard,
        SessionTimeoutWarning,
        TaskInputWrapper,
        SnapshotBuilder,
        StudentAudit,
        TeamEvaluationResults,
        CustomQuestions,
        CustomTasks,
        FeedbackReviewer,
      },
    })
      .use(pinia)
      .use(FloatingVue)
      .use(vfmPlugin)
      .mount(el)
  );
};

initializeVueInstances();
